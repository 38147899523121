


































































import { TableQuery } from "@/api/requests/TableQuery";
import { CdnFile } from "@/types/CdnFile";
import { TableData } from "@/types/Table/TableData";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FileItem from "./FileItem.vue";
import FileUploader from "./FileUploader.vue";
import Pagination from "../../../../components/shared/Table/Pagination.vue";

@Component({
	components: {
		FileItem,
		Pagination,
		FileUploader
	}
})
export default class FilesList extends Vue {
	@Prop({ type: Object }) files!: TableData<CdnFile>;
	@Prop({ type: Array, required: false, default: () => [8, 12, 16] })
	perPageOptions!: number[];

	private activeTab = 0;
	private paginationData: TableQuery = {
		page: 1,
		pageSize: 12,
		sortDirection: "ascending",
		sortColumn: "id",
		searchQuery: "",
		shouldSort: true
	};

	get page() {
		return this.paginationData.page;
	}

	fetchFiles(): void {
		this.$emit("fetchData", this.paginationData);
	}

	tabChange(tab: any): void {
		this.paginationData.page = 1;
		this.activeTab = +tab.index;
		this.fetchFiles();
	}

	@Watch("page")
	@Watch("paginationData.pageSize")
	onPageChanged() {
		this.fetchFiles();
	}
}
