import { authService } from "@/api/authServiceInstance";
import AccountsPage from "@/pages/accounts/Accounts.vue";
import AuthPage from "@/pages/auth/Auth.vue";
import AuthCallbackPage from "@/pages/auth/callback/CallbackPage.vue";
import NotFoundPage from "@/pages/exception/NotFound.vue";
import HomePage from "@/pages/home/Home.vue";
import KeyCodesPage from "@/pages/keyCodes/KeyCodes.vue";
import ProductsPage from "@/pages/products/Products.vue";
import ReportEditorPage from "@/pages/reportsEditor/ReportsEditor.vue";
import ReportsPage from "@/pages/reports/Reports.vue";
import TextResourcesPage from "@/pages/textResources/TextResources.vue";
import LanguagesPage from "@/pages/languages/Languages.vue";
import SectionsPage from "@/pages/sections/Sections.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: "",
		name: "home",
		component: HomePage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/reports/:id?",
		name: "reports",
		component: ReportsPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/textresources/:id?",
		name: "textresources",
		component: TextResourcesPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/products/:id?",
		name: "products",
		component: ProductsPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/auth",
		name: "auth",
		component: AuthPage,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/auth/callback",
		name: "auth.callback",
		component: AuthCallbackPage,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/editor/report",
		name: "reportEditor",
		component: ReportEditorPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/keycodes/:id?",
		name: "keycodes",
		component: KeyCodesPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/accounts/:id?",
		name: "accounts",
		component: AccountsPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/languages",
		name: "languages",
		component: LanguagesPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/sections",
		name: "sections",
		component: SectionsPage,
		meta: {
			requiresAuth: true
		}
	},
	{ path: "*", name: "notFound", component: NotFoundPage }
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((route) => route.meta.requiresAuth)) {
		if (await authService.isNotActive())
			next({ path: "/auth", query: { redirect: to.name } });
		else next();
	}
	next();
});

export default router;
