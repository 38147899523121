const keyCodes = {
	Title: "KeyCodes",
	AccountName: "Account",
	ProductID: "Product #",
	FullCode: "KeyCode",
	Available: "Available",
	Finished: "Complete",
	Partial: "Incomplete",
	MaxUses: "Uses",
	Concluded: "Concluded",
	IsUpgrade: "Free report upgrade",
	IsSample: "Is a sample",
	ViewAccount: "View account"
};

export default keyCodes;
