import _Vue from "vue";
import { Auth } from "@/api/services/auth";
import { authService } from "@/api/authServiceInstance";

export default function ServicesPlugin(Vue: typeof _Vue): void {
	Vue.prototype.$auth = authService;
}

declare module "vue/types/vue" {
	interface Vue {
		$auth: Auth;
	}
}
