const accounts = {
	Title: "Accounts",
	AddAccount: "Add Account",
	EditAccount: "Edit Account",
	Name: "Name",
	NameTooltip:
		"Please enter a descriptive name for this account. This is visible to both administrators and end users, so try to make it as meaningful and descriptive as possible.",
	Description: "Description",
	DescriptionTooltip:
		"Please enter a short description of this account. This may be visible to administrators and managers, so try to make it as meaningful and descriptive as possible.",
	Benchmarks: "Benchmarks",
	OccupationalKeys: "Occupational Keys",
	CustomImages: "Custom Images",
	CustomReports: "Custom Reports",
	InvoicedBilling: "Invoiced Billing",
	SuccessfullyCreated: "Account successfully created",
	SuccessfullyUpdated: "Account successfully updated",
	GraphOption: "Number of Graphs to Use",
	GraphOptionTooltip:
		"Some customers are used to (and prefer) a two-graph report. Our DISC is generally geared toward a 3-graph report, so this selection should always be for 3 graphs unless 2 graphs are specifically requested. If the customer requests 2 graphs, please generate the results off of the first or second graph (this is the next setting).",
	GraphResults: "Graph to Generate Results From",
	GraphResultsTooltip:
		"Some customers prefer to have their results based on the first or second graph for specific applications. Our DISC is generally geared toward the 3rd graph, so this selection should always be for the 3rd graph unless a different graph is specifically requested. If the customer requests 2 graphs (this is the previous setting), please generate the results off of the first or second graph.",
	GraphStyle: "Select Styling for Graph Comparison Report",
	GraphStyleTooltip:
		"This is for the Graph Comparison Report on the keycode page. This is not for the Group Reports, which is determined by the specific Product of the Group report.",
	AssessmentLimit: "Limit By Assessment Qty",
	AssessmentLimitTooltip:
		"This setting will only usually be used along with the 'Allow Usage Billing' option where an account has unlimited use up to a certain assessment quantity.  When this is selected, the account (and all of its sub-accounts) can use any product they want until they reach the number specified in the input.  The quantity of all this account's used assessments is listed on the right.  If the input in the box is less than the number to the right, no participants will have the ability to take any further assessments.  To activate this you must enter a valid value and select the checkbox.",
	ReportBalance: "Report Balance",
	ReportBalanceTooltip:
		"When this value is greater than zero, this account will only be able to purchase any amount of assessments until they have exhausted this amount.  This will probably not be used with any other billing option (meaning that 'Usage Billing' and all of the 'Limit By...' options should probably be disabled).",
	AllowBenchmarks: "Allow Benchmarks",
	AllowBenchmarksTooltip:
		"This box should be checked if the customer needs to be able to use Benchmarks within their account.",
	HasBenchmarkCreatorAccess: "Allow Benchmark Creator",
	HasBenchmarkCreatorAccessTooltip:
		"This gives the account access to the new benchmark creator.",
	AllowOccupationalKeys: "Allow OccupationalKeys",
	AllowOccupationalKeysTooltip:
		"This box should be checked if the customer needs to be able to use OccupationalKeys within their account.",
	AllowCustomization: "Allow Image Customization",
	AllowCustomizationTooltip:
		"If this box is selected, customers will be able to upload a custom account header images and a custom account cover page image.",
	AllowCustomReports: "Allow Report Customization",
	AllowCustomReportsTooltip:
		"This box should be checked if the customer wants to be able to create custom report styles within their account.  These custom report styles will be based off of a product's standard report, but will allow a customer to remove sections they don't require and/or add new static sections.  The added sections will not be style-specific and will display identically for each individual report.",
	AllowEEOC: "Allow EEOC Information",
	AllowEEOCTooltip:
		"Check this box if the customer wants to be able to request EEOC information from participants.",
	AllowContactInfo: "Allow Contact Information",
	AllowContactInfoTooltip:
		"<div style='color:red'>Do NOT use this for any European accounts!!!</div>If this box is checked, every report taken within this account will ask for the participant's name, address and phone number.",
	AllowSUKCs: "Allow Single-Use KeyCodes",
	AllowSUKCsTooltip:
		"If this box is checked, the customer will be able to generate single-use keycodes within keycodes in their account.",
	AllowDistCodes: "Allow Distributor Codes",
	AllowDistCodesTooltip:
		"<div style='color:red'>Are you sure?</div>If this box is checked, the customer (or Superadmins) will be able to generate distributor codes in their account.  If you are doing this for the PK store, you will still need to have IT retrieve the 'simple list' to put on the store.",
	AllowTracking: "Allow Tracking System",
	AllowTrackingTooltip:
		"If this box is checked, administrators will be able to track users in their account through the tracking system.  The tracking system is extremely customizable and somewhat complicated.  The tracking system should never be enabled by default, as a good portion of training and support will need to accompany it.",
	EncryptLinks: "Require Encrypted PDF Links",
	EncryptLinksTooltip:
		"If this box is checked, the PDF Links that are sent out will be encrypted.  Note that enabling this setting will disable any links that were sent out using the previous method.",
	AllowSGI: "Allow SGI Filtering",
	AllowSGITooltip:
		"When this box is selected, the profile search will show checkboxes for Spiritual Gifts.  This setting should only be activated for ministry-based customers using products that include the Spiritual Gifts assessment.",
	AllowTeamRadar: "Allow Team Radar Graph",
	AllowTeamRadarTooltip:
		"If this box is checked, administrators will be able to compare users in a keycode on a radar graph.",
	AllowGroupRpt: "Allow Group Reports",
	AllowGroupRptTooltip:
		"If this box is checked, administrators will be able to view the differences and similarities of a group of users in a single report.",
	PayForChildren: "Financially Responsible",
	PayForChildrenTooltip:
		"When this box is selected, any sub-account of this account will not have the ability to purchase (unless that specific sub-account is also marked as financially responsible).  This setting is for accounts that want to control the products added within sub-accounts and be able to bill them separately (or not at all).  Resellers and large organizations with multiple locations may be examples of accounts that would want this.",
	InvoiceBilling: "Invoice Billing",
	AllowUsageBilling: "Allow Invoiced Billing",
	AllowUsageBillingTooltip:
		"This box should ONLY be checked for customers who have (a) billing agreements in place and (b) billing procedures in place.  With this box checked, no up-front payment is required for any products used within the account and it is expected that we will bill the customer for usage at agreed-upon intervals.",
	DateLimit: "Limit By Date",
	DateLimitTooltip:
		"When this is selected, the account (and all of its sub-accounts) can use any product they want until they reach the date specified in the input.  The current date is listed on the right.  If the input in the box is less than the date to the right, no participants will have the ability to take any further assessments.  To activate this you must enter a valid value and select the checkbox.",
	CreditCard: "Credit card",
	TaxExtempt: "Tax Extempt",
	TaxExtemptTooltip:
		"Tax exempt customers will not have any state taxes added to their purchases made with domestic credit cards.",
	Country: "Country",
	CountryTooltip:
		"By Specifying the country location of the account, we can provide filtering based on location for the dashboard slider.",
	ParentAccount: "Parent Account",
	ParentAccountTooltip:
		"Each new account must be categorized under the appropriate parent account here.  If this account shouldn't belong under any other specific account, then just select the top-level 'PeopleKeys' account as a default.",
	FeatureAccess: "Feature access",
	WLSEnabled: "Allow PLW",
	WLSEnabledTooltip: "PLW can be connected to this account",
	WidgetAccessEnabled: "Widget access enabled",
	WidgetAccessEnabledTooltip: "Widget can be connected to this account",
	ApiEnabled: "API Enabled",
	ApiEnabledTooltip: "API can be connected to this account",
	ApiKey: "API Key",
	ApiKeyTooltip: "API Key",
	GenerateApiKey: "Generate API Key",
	Details: "Details",
	Users: "Users",
	Products: "Products",
	GroupReportingProducts: "Group Reporting Products",
	KeyCodes: "KeyCodes",
	Payments: "Payments",
	LimitByDollarAmount: "Limit By Dollar Amount",
	LimitByDollarAmountTooltip:
		"Click this link to go to account balance management.",
	PaymentProfileID: "CIM Profile",
	PaymentProfileIDTooltip:
		"This is the profile which links the account to Authorize.Net",
	InternationalPaymentProfileID: "International CIM Profile",
	InternationalPaymentProfileIDTooltip:
		"This is the profile which links the international account to Authorize.Net",
	Notes: "Internal PK Notes",
	NotesTooltip:
		"Enter any notes here that are for the sole use of PeopleKeys' employees. This is NOT visible to administrators and managers, so you can enter accounting info, etc.",
	SelectedAssessmentApp: "Assessment App",
	SelectedAssessmentAppTooltip:
		"Choose where the assessment invite links will lead",
	PurchaseReport: "Purchase report",
	PurchaseStatisticsAllTime: "Purchase statistics - all time",
	PurchaseStatisticsLastMonth: "Purchase statistics - last 30 days",
	SumOfPurchasesUsage: "Sum of purchases (usage)",
	SumOfPurchasesUsageTooltip:
		"The original sum of purchases - counts created assessments",
	SumOfPurchasedAssessments: "Sum of purchased assessments",
	SumOfPurchasedAssessmentsTooltip:
		"New calculation - purchased uses * unit price",
	SumOfPurchasedGroupReports: "Sum of purchased group reports",
	SumOfPurchasedGroupReportsTooltip:
		"New calculation - purchased uses * unit price",
	TotalSumOfPurchases: "Total sum of purchases",
	TotalSumOfPurchasesTooltip:
		"Total sum of purchases (Sum of purchased assessments + sum of purchased group products)",
	ManageLBDABalance: "Manage LBDA balance"
};

export default accounts;
