const reports = {
	TableTitle: "Text resources",
	EditTitle: "Text resource",
	Remove: "Delete Selected",
	Add: "Add Text resource",
	AddRuleset: "Add Rule set",
	AddRule: "Add Rule",
	Save: "Save",
	Edit: "Edit",
	Cancel: "Cancel",
	Id: "Resource Id",
	Language: "Language",
	Name: "Name",
	StyleBlend: "Style Blend",
	Text: "Text",
	TextResourceTypeName: "Type",
	RulesNo: "# of Rules",
	ModifiedDate: "Modified date",
	Translations: "Translations",
	AvailableLanguages: "Available languages",
	AddTranslation: "Add Translation",
	RemoveTranslation: "Remove translation",
	SuccessfullySaved: "Text resource saved successfully!",
	EmptyTranslations:
		"There is no translation for this text resource. Add at least one in order to save."
};

export default reports;
