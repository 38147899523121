


























import { Component, Prop, Vue, Emit } from "vue-property-decorator";

@Component
export default class FileUploadItem extends Vue {
	@Prop({ type: File }) file!: File;
	@Prop({ type: String }) fileUrl!: string;
	@Prop({ type: Number }) index!: number;

	@Emit("removeFile")
	removeFile(index: number) {
		return index;
	}
}
