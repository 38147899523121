

















































import { TableQuery } from "@/api/requests/TableQuery";
import { GetAccountsResponse } from "@/api/responses/accounts/GetAccountsResponse";
import { TableData } from "@/types/Table/TableData";
import { Component, Vue } from "vue-property-decorator";
import AccountForm from "./AccountForm.vue";

@Component({
	components: {
		AccountForm
	}
})
export default class AccountsTable extends Vue {
	private tableColumns = [
		{
			prop: "id",
			label: "#"
		},
		{
			prop: "name",
			label: this.$t("accounts.Name"),
			minWidth: 200
		},
		{
			prop: "description",
			label: this.$t("accounts.Description"),
			minWidth: 200
		},
		{
			prop: "allowBenchmarks",
			label: this.$t("accounts.Benchmarks"),
			minWidth: 100
		},
		{
			prop: "allowOccupationalKeys",
			label: this.$t("accounts.OccupationalKeys"),
			minWidth: 100
		},
		{
			prop: "allowCustomization",
			label: this.$t("accounts.CustomImages"),
			minWidth: 100
		},
		{
			prop: "allowCustomReports",
			label: this.$t("accounts.CustomReports"),
			minWidth: 100
		},
		{
			prop: "allowUsageBilling",
			label: this.$t("accounts.InvoicedBilling"),
			minWidth: 100
		}
	];
	private tableData = { results: [], count: 10, currentPage: 1 } as TableData<
		GetAccountsResponse
	>;
	private perPageOptions = [5, 10, 15];
	private loading = true;
	private modalShow = false;
	private modalHeader = "";

	$refs!: {
		accountFormRef: AccountForm;
	};

	async fetchData(pagination: TableQuery): Promise<void> {
		this.loading = true;
		this.tableData.results.length = 0;
		const { data, success } = await this.$accounts.getAccounts(pagination);
		if (success) {
			this.tableData = data;
		}
		this.loading = false;
	}

	async handleEdit(id: number): Promise<void> {
		this.modalHeader = this.$t("accounts.EditAccount").toString();
		this.$refs.accountFormRef.fill(id);
		this.modalShow = true;
	}

	handleAdd(): void {
		this.modalHeader = this.$t("accounts.AddAccount").toString();
		this.$refs.accountFormRef.clear();
		this.modalShow = true;
	}

	closeModal(): void {
		this.modalShow = false;
		this.fetchData({
			page: 1,
			pageSize: 5,
			sortDirection: "descending",
			sortColumn: this.tableColumns[0].prop,
			searchQuery: "",
			shouldSort: false
		} as TableQuery);
	}

	mounted(): void {
		if (this.$route.params.id) {
			this.handleEdit(+this.$route.params.id);
		}
	}
}
