import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { TableQuery } from "../requests/TableQuery";
import { GetCountriesResponse } from "../responses/countries/GetCountriesResponse";

export class Countries extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getCountries(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<GetCountriesResponse>>> {
		return this.get<
			ApiResponse<TableData<GetCountriesResponse>>,
			AxiosResponse<ApiResponse<TableData<GetCountriesResponse>>>
		>("/Countries", {
			params: tableQuery
		})
			.then(this.success)
			.catch(this.error);
	}

	public getCountryById(
		id: number
	): Promise<ApiResponse<GetCountriesResponse>> {
		return this.get<
			ApiResponse<GetCountriesResponse>,
			AxiosResponse<ApiResponse<GetCountriesResponse>>
		>(`/Countries/${id}`)
			.then(this.success)
			.catch(this.error);
	}
}
