import { render, staticRenderFns } from "./FilesListModal.vue?vue&type=template&id=adf6d6a4&scoped=true&"
import script from "./FilesListModal.vue?vue&type=script&lang=ts&"
export * from "./FilesListModal.vue?vue&type=script&lang=ts&"
import style0 from "./FilesListModal.vue?vue&type=style&index=0&id=adf6d6a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adf6d6a4",
  null
  
)

export default component.exports