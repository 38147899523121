import Notifications from "@/components/shared/Notification";
import SideBar from "@/components/shared/Sidebar";
import axiosPlugin from "@/plugins/axiosPlugin";
import globalComponents from "@/plugins/globalComponents";
import globalDirectives from "@/plugins/globalDirectives";
import stylesPlugin from "@/plugins/globalStyles";
import servicesPlugin from "@/plugins/servicesPlugin";
import "@/plugins/validationPlugin";
import { SharedReportEditor } from "@peoplekeys/shared-components/src";
import BootstrapVue from "bootstrap-vue";
import Vue from "vue";
import VueCodemirror from "vue-codemirror";
import App from "./App.vue";
import i18n from "./locales/i18n";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
Vue.use(stylesPlugin);
Vue.use(axiosPlugin);
Vue.use(servicesPlugin);
Vue.use(globalComponents);
Vue.use(globalDirectives);
Vue.use(SideBar);
Vue.use(Notifications);
Vue.use(VueCodemirror);
Vue.use(SharedReportEditor, { store });
Vue.use(BootstrapVue);

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App)
}).$mount("#app");
