





import { Component, Vue } from "vue-property-decorator";
import ProductsTable from "./content/ProductsTable.vue";
import ProductForm from "./content/ProductForm.vue";

@Component({
	components: {
		ProductsTable,
		ProductForm
	}
})
export default class ProductsPage extends Vue {}
