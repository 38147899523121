






















































































































































import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { Validatorable } from "@/interfaces/Validatorable";
import { RuleSet } from "@/types/RuleSet";
import { Rule } from "@/types/Rule";

@Component
export default class RulesList extends Vue {
	@Prop({ type: Array }) rules!: Array<RuleSet>;
	@Prop({ type: Number }) resourceId!: number;

	$refs!: {
		form: Validatorable;
	};

	private activeRuleIndex = "";
	private activeRuleSetIndex = "";
	private isEdit = false;
	private editableRule: Rule = {
		id: 0,
		name: "",
		rule: ""
	};

	private get editableItem() {
		return {
			rule: this.editableRule,
			ruleSetIndex: +this.activeRuleSetIndex - 1,
			ruleIndex: +this.activeRuleIndex - 1
		};
	}

	editRule(rule: Rule): void {
		this.editableRule = { ...rule };
		this.isEdit = true;
	}

	resetEditable(): void {
		this.isEdit = false;
	}

	clearErrors(): void {
		this.$refs.form.reset();
	}

	resetAccordion(): void {
		this.activeRuleIndex = "";
		this.activeRuleSetIndex = "";
	}

	@Emit("onRuleSave")
	async saveRule() {
		this.clearErrors();
		const isValid = await this.$refs.form.validate();
		if (isValid) {
			const ruleToDelete = {
				...this.editableItem,
				rule: {
					id: this.editableItem.rule.id,
					rule: this.editableItem.rule.rule,
					ruleName: this.editableItem.rule.name
				}
			};

			this.resetEditable();

			return {
				...ruleToDelete
			};
		}
	}

	@Emit("onRuleDelete")
	removeRule() {
		const ruleToDelete = this.editableItem;
		this.resetAccordion();

		return {
			...ruleToDelete
		};
	}

	@Emit("closeModal")
	closeModal() {
		this.resetAccordion();
		return;
	}

	@Watch("resourceId")
	async onResourceChange() {
		this.resetAccordion();
	}
}
