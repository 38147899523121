import { Api } from "@/api/api";
import { TextResourceQuery } from "@/api/requests/textResource/TextResourceQuery";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { AddTextResourceRequest } from "../requests/textResource/AddTextResourceRequest";
import { RemoveTextResourcesRequest } from "../requests/textResource/RemoveTextResourcesRequest";
import { SaveTextResourceRequest } from "../requests/textResource/SaveTextResourceRequest";
import { GetTextResourceResponse } from "../responses/textResources/GetTextResourceResponse";
import { GetTextResourcesResponse } from "../responses/textResources/GetTextResourcesResponse";

export class TextResources extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public addTextResource(
		data: AddTextResourceRequest
	): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			AddTextResourceRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/TextResources`, data)
			.then(this.success)
			.catch(this.error);
	}

	public saveTextResource(
		id: number,
		data: SaveTextResourceRequest
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			SaveTextResourceRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/TextResources/${id}`, data)
			.then(this.success)
			.catch(this.error);
	}

	public getTextResources(
		params?: TextResourceQuery
	): Promise<ApiResponse<TableData<GetTextResourcesResponse>>> {
		return this.get<
			ApiResponse<GetTextResourcesResponse>,
			AxiosResponse<ApiResponse<TableData<GetTextResourcesResponse>>>
		>(`/TextResources/all`, { params })
			.then(this.success)
			.catch(this.error);
	}

	public getTextResource(
		id: number
	): Promise<ApiResponse<GetTextResourceResponse>> {
		return this.get<
			ApiResponse<GetTextResourceResponse>,
			AxiosResponse<ApiResponse<GetTextResourceResponse>>
		>(`/TextResources/${id}`)
			.then(this.success)
			.catch(this.error);
	}

	public removeTextResources(
		data: RemoveTextResourcesRequest
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			RemoveTextResourcesRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/TextResources/delete`, data)
			.then(this.success)
			.catch(this.error);
	}

	public removeTextResource(id: number): Promise<ApiResponse<boolean>> {
		return this.delete<
			ApiResponse<boolean>,
			AxiosResponse<ApiResponse<boolean>>
		>(`/TextResources/${id}`)
			.then(this.success)
			.catch(this.error);
	}

	public removeRuleCollectionFromSection(
		ruleCollectionId: number,
		sectionId: number
	): Promise<ApiResponse<boolean>> {
		return this.delete<
			ApiResponse<boolean>,
			AxiosResponse<ApiResponse<boolean>>
		>(`/TextResources/${ruleCollectionId}/fromSection/${sectionId}`)
			.then(this.success)
			.catch(this.error);
	}
}
