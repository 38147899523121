





import { Component, Vue } from "vue-property-decorator";
import TextResourcesTable from "./content/TextResourcesTable.vue";

@Component({
	components: {
		TextResourcesTable
	}
})
export default class TextResourcesPage extends Vue {}
