









































import { CdnFile } from "@/types/CdnFile";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FileItem extends Vue {
	@Prop({ type: Object }) file!: CdnFile;

	generateImageTag(imgUrl: string): string {
		return `<img src="${imgUrl}" />`;
	}
}
