import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { GetZohoProductResponse } from "@/api/responses/zohoProducts/GetZohoProductResponse";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { TableQuery } from "../requests/TableQuery";

export class ZohoProducts extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getZohoProducts(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<GetZohoProductResponse>>> {
		return this.get<
			ApiResponse<TableData<GetZohoProductResponse>>,
			AxiosResponse<ApiResponse<TableData<GetZohoProductResponse>>>
		>("/ZohoProducts", {
			params: tableQuery
		})
			.then(this.success)
			.catch(this.error);
	}

	public getZohoProductById(
		id: number
	): Promise<ApiResponse<GetZohoProductResponse>> {
		return this.get<
			ApiResponse<GetZohoProductResponse>,
			AxiosResponse<ApiResponse<GetZohoProductResponse>>
		>(`/ZohoProducts/${id}`)
			.then(this.success)
			.catch(this.error);
	}
}
