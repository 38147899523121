














































































import { Languable } from "@/interfaces/store/Languable";
import ACTIONS from "@/store/action-definitions";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component
export default class AppNavbar extends Vue {
	@Action(ACTIONS.SET_LANGUAGE) setLanguage: (locale: string) => void;
	@Getter("getLanguages") languages!: string[];
	@Getter("getLanguage") language!: Languable;

	private showNavbar = false;
	private activeNotifications = false;

	public capitalizeFirstLetter(string: string): string {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	public toggleNotificationDropDown(): void {
		this.activeNotifications = !this.activeNotifications;
	}

	public closeDropDown(): void {
		this.activeNotifications = false;
	}

	public toggleSidebar(): void {
		this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
	}

	public toggleNavbar(): void {
		this.showNavbar = !this.showNavbar;
	}

	public hideSidebar(): void {
		this.$sidebar.displaySidebar(false);
	}

	public isCurrentLanguage(langauge: Languable): boolean {
		return langauge.locale === this.language.locale;
	}

	public logout(): void {
		this.$auth.logout();
	}

	get routeName(): string {
		const { name } = this.$route;
		if (name) return this.capitalizeFirstLetter(name);
		return "";
	}
}
