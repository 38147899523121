const languages = {
	Title: "Languages",
	Name: "Name",
	Code: "Code",
	English: "English",
	Polish: "Polish",
	AddLanuage: "Add Language",
	EditLanguage: "Edit Language",
	RemoveLanguage: "Remove Language",
	SuccessfullySaved: "Language successfully saved",
	SuccessfullyDeleted: "Language successfully deleted"
};

export default languages;
