import { Api } from "@/api/api";
import { AddRuleCollectionToSectionRequest } from "@/api/requests/ruleCollections/AddRuleCollectionToSectionRequest";
import { TableQuery } from "@/api/requests/TableQuery";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { UpdateRuleCollectionRequest } from "../requests/ruleCollections/UpdateRuleCollectionRequest";
import { GetRuleCollectionsResponse } from "../responses/ruleCollections/GetRuleCollectionsResponse";

export class RuleCollections extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public removeRuleCollectionFromSection(
		ruleCollectionId: number,
		sectionId: number
	): Promise<ApiResponse<boolean>> {
		return this.delete<
			ApiResponse<boolean>,
			AxiosResponse<ApiResponse<boolean>>
		>(`/RuleCollections/${ruleCollectionId}/${sectionId}`)
			.then(this.success)
			.catch(this.error);
	}

	public getRuleCollections(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<GetRuleCollectionsResponse>>> {
		return this.get<
			ApiResponse<GetRuleCollectionsResponse>,
			AxiosResponse<ApiResponse<TableData<GetRuleCollectionsResponse>>>
		>(`/RuleCollections/all`, { params: tableQuery })
			.then(this.success)
			.catch(this.error);
	}

	public addRuleCollectionToSection(
		sectionId: number,
		data: AddRuleCollectionToSectionRequest
	): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			AddRuleCollectionToSectionRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/RuleCollections/${sectionId}`, data)
			.then(this.success)
			.catch(this.error);
	}

	public updateRuleCollection(
		id: number,
		data: UpdateRuleCollectionRequest
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			UpdateRuleCollectionRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/RuleCollections/${id}`, data)
			.then(this.success)
			.catch(this.error);
	}
}
