






















































































































import { Component, Vue } from "vue-property-decorator";
import { Validatorable } from "@/interfaces/Validatorable";
import i18n from "@/locales/i18n";
import { CreateReportRequest } from "@/api/requests/reports/CreateReportRequest";
import { UpdateReportRequest } from "@/api/requests/reports/UpdateReportRequest";
import { GetReportsByIdResponse } from "@/api/responses/reports/GetReportsByIdResponse";
import { TableQuery } from "@/api/requests/TableQuery";
import { Language } from "@/types/Language";
import ACTIONS from "@/store/action-definitions";
import { Action, Getter } from "vuex-class";

@Component
export default class ReportForm extends Vue {
	private data = this.getEmptyReport();
	private errorMessage = "";
	private loading = false;
	private defaultLanguageChoosen = "";

	$refs!: {
		form: Validatorable;
	};

	@Getter("isAddReportForm") isAddReportForm!: boolean;

	@Action(ACTIONS.SET_ADD_REPORT_FORM_STATE) setAddReportFormState: (
		isAddReportForm: boolean
	) => void;

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	async checkForm(runReportEditor: boolean): Promise<void> {
		this.clearErrors();
		const isValid = await this.$refs.form.validate();
		if (isValid) await this.saveChanges(runReportEditor);
	}
	async saveChanges(runReportEditor: boolean): Promise<void> {
		let isSuccess = false;
		let id = 0;
		if (this.isAddReportForm) {
			const { data, success } = await this.$reports.createReports({
				name: this.data.name,
				description: this.data.description,
				defaultLanguageId: this.data.defaultLanguage.id
			} as CreateReportRequest);
			isSuccess = success;
			id = data.id;
		} else {
			isSuccess = (
				await this.$reports.updateReports(this.data.id, {
					name: this.data.name,
					description: this.data.description,
					defaultLanguageId: this.data.defaultLanguage.id
				} as UpdateReportRequest)
			).success;
			id = this.data.id;
		}
		if (isSuccess) {
			if (this.isAddReportForm) {
				this.setMessage({
					message: i18n.t("reports.SuccessfullyCreated").toString(),
					type: "success"
				});
			} else {
				this.setMessage({
					message: i18n.t("reports.SuccessfullyUpdated").toString(),
					type: "success"
				});
			}
			if (runReportEditor) {
				this.$router.push({
					name: "reportEditor",
					query: { reportId: id.toString() }
				});
			} else {
				this.$emit("closeModal");
			}
		}
	}

	clearErrors(): void {
		this.$refs.form.reset();
	}

	getEmptyReport(): GetReportsByIdResponse {
		return {
			id: 0,
			name: "",
			description: ""
		} as GetReportsByIdResponse;
	}

	clear(): void {
		this.loading = true;
		this.setAddReportFormState(true);
		this.data = this.getEmptyReport();
		this.defaultLanguageChoosen = "";
		this.loading = false;
	}

	async fill(id: number): Promise<void> {
		this.loading = true;
		this.setAddReportFormState(false);
		if (id > 0) {
			this.data = this.getEmptyReport();
			const { data, success } = await this.$reports.getReportsById(id);
			if (success) {
				this.data = data;
				this.defaultLanguageChoosen = data.defaultLanguage.name;
				this.loading = false;
			}
		}
	}

	async getDefaultLanguages(
		queryString: string,
		callBack: (data: Language[]) => void
	): Promise<void> {
		const { data, success } = await this.$languages.getLanguages({
			page: 1,
			pageSize: 25,
			sortDirection: "ascending",
			sortColumn: "id",
			searchQuery: queryString,
			shouldSort: true
		} as TableQuery);
		if (success) {
			callBack(data.results);
		}
	}

	handleSelectDefaultLanguages(item: Language) {
		this.defaultLanguageChoosen = item.name;
		this.data.defaultLanguage = item;
	}
}
