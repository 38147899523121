const products = {
	Title: "Products",
	Name: "Name",
	Description: "Description",
	DefaultPrice: "Default price",
	IsAGroupReport: "Group Report",
	NoOfKeyCodes: "# of KeyCodes",
	AddProduct: "Add product",
	EditProduct: "Edit product",
	ZohoProduct: "Zoho product",
	DefaultSurveyQuestionGroups: "Additional Questions",
	AssessmentOrder: "Product Assessments",
	Options: "Options",
	Choosen: "Choosen",
	ProductVersionName: "Version Name",
	SuccessfullyCreated: "Product successfully created",
	SuccessfullyUpdated: "Product successfully updated",
	Report: "Report",
	Empty: "Empty",
	GoToKeyCodes: "Go to KeyCodes"
};

export default products;
