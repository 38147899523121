const sections = {
	Title: "Section",
	SelectPlaceholder: "Select",
	Name: "Name",
	Type: "Type",
	AddTemplate: "Add template content",
	TableTitle: "Sections",
	Remove: "Delete Selected",
	NoOfReports: "# of reports",
	DeleteTooltip: "Delete section",
	UnsavedChanges: "There are unsaved changes. Would you like to save?",
	AlreadyExists: "Section already exists in this report",
	CloneSection: "Clone section",
	CloneName: "New section name"
};

export default sections;
