








import { Component, Vue } from "vue-property-decorator";

@Component({
	components: {}
})
export default class AuthPage extends Vue {
	private loading = true;

	constructor() {
		super();
		this.$auth.login(this.$route.query.redirect.toString());
		this.loading = false;
	}
}
