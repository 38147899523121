import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { AddUpdateTextResourcesRequest } from "../requests/sections/AddUpdateTextResourcesRequest";
import { CloneSectionRequest } from "../requests/sections/CloneSectionRequest";
import { SaveSectionRequest } from "../requests/sections/SaveSectionRequest";
import { SectionQuery } from "../requests/sections/SectionQuery";
import { RemoveTextResourcesRequest } from "../requests/textResource/RemoveTextResourcesRequest";
import { GetSectionResponse } from "../responses/sections/GetSectionResponse";
import { ImportTextResourcesResponse } from "../responses/sections/ImportTextResourcesResponse";

export class Sections extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getSection(id: number): Promise<ApiResponse<GetSectionResponse>> {
		return this.get<
			ApiResponse<GetSectionResponse>,
			AxiosResponse<ApiResponse<GetSectionResponse>>
		>(`/Sections/${id}`)
			.then(this.success)
			.catch(this.error);
	}

	public detachTextResources(
		sectionId: number,
		data: RemoveTextResourcesRequest
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			RemoveTextResourcesRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Sections/${sectionId}/textResources/detach`, data)
			.then(this.success)
			.catch(this.error);
	}

	public addSection(data: SaveSectionRequest): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			SaveSectionRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Sections`, data)
			.then(this.success)
			.catch(this.error);
	}

	public updateSection(
		data: SaveSectionRequest
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			SaveSectionRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Sections`, data)
			.then(this.success)
			.catch(this.error);
	}

	public cloneSection(
		sectionId: number,
		cloneSectionRequest: CloneSectionRequest
	): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			CloneSectionRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Sections/clone/${sectionId}`, cloneSectionRequest)
			.then(this.success)
			.catch(this.error);
	}

	public exportTextResources(sectionId: number): Promise<BlobPart> {
		return this.get<BlobPart, AxiosResponse<any>>(
			`/Sections/exportTextResources/${sectionId}`,
			{ responseType: "blob" }
		)
			.then(this.success)
			.catch(this.error);
	}

	public importTextResources(
		file: FormData
	): Promise<ApiResponse<ImportTextResourcesResponse[]>> {
		return this.post<
			ApiResponse<ImportTextResourcesResponse[]>,
			FormData,
			AxiosResponse<ApiResponse<ImportTextResourcesResponse[]>>
		>(`/Sections/importTextResources`, file)
			.then(this.success)
			.catch(this.error);
	}

	public addUpdateTextResources(
		sectionId: number,
		data: AddUpdateTextResourcesRequest[]
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			AddUpdateTextResourcesRequest[],
			AxiosResponse<ApiResponse<boolean>>
		>(`/Sections/${sectionId}/addUpdateTextResources`, data)
			.then(this.success)
			.catch(this.error);
	}

	public getSections(
		params: SectionQuery
	): Promise<ApiResponse<TableData<GetSectionResponse>>> {
		return this.get<
			ApiResponse<TableData<GetSectionResponse>>,
			AxiosResponse<ApiResponse<TableData<GetSectionResponse>>>
		>(`/Sections/all`, { params })
			.then(this.success)
			.catch(this.error);
	}

	public getGlobalSections(
		params: SectionQuery
	): Promise<ApiResponse<TableData<GetSectionResponse>>> {
		return this.get<
			ApiResponse<TableData<GetSectionResponse>>,
			AxiosResponse<ApiResponse<TableData<GetSectionResponse>>>
		>(`/Sections/global`, { params })
			.then(this.success)
			.catch(this.error);
	}

	public deleteSections(ids: number[]): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			number[],
			AxiosResponse<ApiResponse<boolean>>
		>(`/Sections/delete`, ids)
			.then(this.success)
			.catch(this.error);
	}
}
