import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { GetSurveyQuestionGroupsResponse } from "@/api/responses/surveyQuestionGroups/GetSurveyQuestionGroupsResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export class SurveyQuestionGroups extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getSurveyQuestionGroups(): Promise<
		ApiResponse<GetSurveyQuestionGroupsResponse[]>
	> {
		return this.get<
			ApiResponse<GetSurveyQuestionGroupsResponse[]>,
			AxiosResponse<ApiResponse<GetSurveyQuestionGroupsResponse[]>>
		>("/SurveyQuestionGroups")
			.then(this.success)
			.catch(this.error);
	}
}
