import { GetAssessmentContext } from "@/api/responses/assessments/GetAssessmentContext";
import { GetAssessmentsTypeResponse } from "@/api/responses/assessmentsTypes/GetAssessmentsTypeResponse";
import { ReportSectionDto } from "@/api/responses/reports/dtos/ReportSectionDto";
import { GetSectionResponse } from "@/api/responses/sections/GetSectionResponse";
import { Messageable } from "@/interfaces/store/Messageable";
import { Statable } from "@/interfaces/store/Statable";
import i18n from "@/locales/i18n";
import { Language } from "@/types/Language";
import { ActionTree } from "vuex";
import ACTIONS from "./action-definitions";
import MUTATIONS from "./mutation-definitions";

export const actions: ActionTree<Statable, any> = {
	[ACTIONS.SET_EDITOR_SECTIONS]: ({ commit }, sections: ReportSectionDto[]) =>
		commit(MUTATIONS.SET_EDITOR_SECTIONS, sections),
	[ACTIONS.SET_CURRENT_SECTION]: ({ commit }, sectionId: number) =>
		commit(MUTATIONS.SET_CURRENT_SECTION, sectionId),
	[ACTIONS.SET_MESSAGE]: ({ commit }, message: Messageable) =>
		commit(MUTATIONS.SET_MESSAGE, message),
	[ACTIONS.SET_EDITOR_DIRTY]: ({ commit }, isDirty: boolean) =>
		commit(MUTATIONS.SET_EDITOR_DIRTY, isDirty),
	[ACTIONS.SET_LANGUAGES]: async (
		{ commit },
		getLanguagesCallback: Function
	) => {
		const { data, success } = await getLanguagesCallback();
		if (success) {
			commit(MUTATIONS.SET_LANGUAGES, data);
		}
	},
	[ACTIONS.SET_LANGUAGE]: async ({ commit, getters }, newLocale: string) => {
		if (newLocale !== getters.getShortLocale) {
			commit(MUTATIONS.SET_LANGUAGE, newLocale);
			// Validator.localize(newLocale.substring(0, 5));
			i18n.locale = newLocale.substring(0, 5);
		}
	},
	[ACTIONS.SET_ASSESSMENT_CONTEXT]: (
		{ commit },
		assessmentContext: GetAssessmentContext
	) => {
		commit(MUTATIONS.SET_ASSESSMENT_CONTEXT, assessmentContext);
	},
	[ACTIONS.SET_ASSESSMENT_TYPES]: (
		{ commit },
		assessmentTypes: Array<GetAssessmentsTypeResponse>
	) => {
		commit(MUTATIONS.SET_ASSESSMENT_TYPES, assessmentTypes);
	},
	[ACTIONS.SET_PREVIEW_HTML]: ({ commit }, previewHtml: string) => {
		commit(MUTATIONS.SET_PREVIEW_HTML, previewHtml);
	},
	[ACTIONS.SET_SECTION_ID]: ({ commit }, sectionId: number) => {
		commit(MUTATIONS.SET_SECTION_ID, sectionId);
	},
	[ACTIONS.SET_SECTION_NAME]: ({ commit }, sectionName: string) => {
		commit(MUTATIONS.SET_SECTION_NAME, sectionName);
	},
	[ACTIONS.SET_SHOW_CONFIMRATION_MODAL]: ({ commit }, show: boolean) => {
		commit(MUTATIONS.SET_SHOW_CONFIMRATION_MODAL, show);
	},
	[ACTIONS.SET_STATUS_CONFIRMATION_MODAL]: ({ commit }, status: boolean) => {
		commit(MUTATIONS.SET_STATUS_CONFIRMATION_MODAL, status);
	},
	[ACTIONS.SET_ADD_REPORT_FORM_STATE]: (
		{ commit },
		isAddReportForm: boolean
	) => {
		commit(MUTATIONS.SET_ADD_REPORT_FORM_STATE, isAddReportForm);
	},
	[ACTIONS.SET_EDITED_LANGUAGE]: ({ commit }, editedLanguage: Language) => {
		commit(MUTATIONS.SET_EDITED_LANGUAGE, editedLanguage);
	},
	[ACTIONS.SET_PREVIEW_ERRORS]: ({ commit }, previewErrors: string[]) => {
		commit(MUTATIONS.SET_PREVIEW_ERRORS, previewErrors);
	},
	[ACTIONS.SET_SECTION]: ({ commit }, section: GetSectionResponse) => {
		commit(MUTATIONS.SET_SECTION, section);
	}
};
