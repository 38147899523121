var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-bar',{attrs:{"backgroundColor":"white"}},[_c('div',{staticClass:"d-flex align-items-center py-2 sidebar-toggler",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleSidebar.apply(null, arguments)}}},[_c('div',{staticClass:"navbar-toggle",class:{ toggled: _vm.$sidebar.showSidebar }},[_c('navbar-toggle-button')],1)]),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
				name: _vm.$t('layout.Reports'),
				icon: 'now-ui-icons files_single-copy-04'
			}}},[_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('layout.Reports'),
					path: 'reports',
					child: true
				}}}),_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('layout.Sections'),
					path: 'sections',
					child: true
				}}}),_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('layout.TextResources'),
					path: 'textresources',
					child: true
				}}}),_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('layout.Languages'),
					path: 'languages',
					child: true
				}}})],1),_c('sidebar-item',{attrs:{"link":{
				name: _vm.$t('layout.Products'),
				icon: 'now-ui-icons business_briefcase-24',
				path: 'products'
			}}}),_c('sidebar-item',{attrs:{"link":{
				name: _vm.$t('layout.KeyCodes'),
				icon: 'now-ui-icons objects_key-25',
				path: 'keycodes'
			}}}),_c('sidebar-item',{attrs:{"link":{
				name: _vm.$t('layout.Accounts'),
				icon: 'now-ui-icons business_badge',
				path: 'accounts'
			}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }