import accounts from "./accounts";
import auth from "./auth";
import errors from "./errors";
import files from "./files";
import keyCodes from "./keyCodes";
import languages from "./languages";
import layout from "./layout";
import products from "./products";
import reports from "./reports";
import reportsEditor from "./reportsEditor";
import rules from "./rules";
import sections from "./sections";
import table from "./table";
import textResources from "./textResources";
import modals from "./modals";

const en = {
	table,
	reports,
	products,
	errors,
	auth,
	keyCodes,
	accounts,
	textResources,
	sections,
	languages,
	layout,
	reportsEditor,
	rules,
	files,
	modals
};

export default en;
