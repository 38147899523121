import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export class Rules extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public removeRule(id: number): Promise<ApiResponse<boolean>> {
		return this.delete<
			ApiResponse<boolean>,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Rules/${id}`)
			.then(this.success)
			.catch(this.error);
	}
}
