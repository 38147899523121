const languages = {
	Logout: "Logout",
	Reports: "Reports",
	GlobalReports: "Global Reports",
	TextResources: "Text Resources",
	Products: "Products",
	KeyCodes: "Key Codes",
	Accounts: "Accounts",
	Languages: "Languages",
	Sections: "Sections"
};

export default languages;
