import { Api } from "@/api/api";
import { TableQuery } from "@/api/requests/TableQuery";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { GetProductResponse } from "@/api/responses/products/GetProductResponse";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export class Products extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getProducts(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<GetProductResponse>>> {
		return this.get<
			ApiResponse<TableData<GetProductResponse>>,
			AxiosResponse<ApiResponse<TableData<GetProductResponse>>>
		>("/Products", {
			params: tableQuery
		})
			.then(this.success)
			.catch(this.error);
	}

	public createProducts(
		data: GetProductResponse
	): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			GetProductResponse,
			AxiosResponse<ApiResponse<boolean>>
		>("/Products", data)
			.then(this.success)
			.catch(this.error);
	}

	public getProductById(
		id: number
	): Promise<ApiResponse<GetProductResponse>> {
		return this.get<
			ApiResponse<GetProductResponse>,
			AxiosResponse<ApiResponse<GetProductResponse>>
		>(`/Products/${id}`)
			.then(this.success)
			.catch(this.error);
	}

	public updateProduct(
		id: number,
		data: GetProductResponse
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			GetProductResponse,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Products/${id}`, data)
			.then(this.success)
			.catch(this.error);
	}
}
