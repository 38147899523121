var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.data)?_c('div',[_c('ValidationObserver',{ref:"form"},[_c('div',[_c('div',{staticClass:"field-panel"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-1 f"},[_vm._v(" "+_vm._s(_vm.$t("textResources.Name"))+" ")]),_c('el-input',{class:[{ 'is-invalid': errors[0] }],attrs:{"type":"text","clearable":""},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('span',{staticClass:"text-danger invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1182851972)})],1)]),_c('div',{staticClass:"row panel-container rules-container"},[_c('div',{staticClass:"col-6"},[_c('div',[_c('el-select',{staticClass:"select-primary mb-3",staticStyle:{"width":"200px"},attrs:{"disabled":!_vm.availableLanguages.length,"placeholder":"Select language"},model:{value:(_vm.selectedLanguageIndex),callback:function ($$v) {_vm.selectedLanguageIndex=$$v},expression:"selectedLanguageIndex"}},_vm._l((_vm.availableLanguages),function(item){return _c('el-option',{key:item.index,staticClass:"select-default",attrs:{"label":item.label,"value":item.index}})}),1),_c('el-popover',{attrs:{"placement":"right","width":"400","trigger":"manual"},model:{value:(_vm.showPopover),callback:function ($$v) {_vm.showPopover=$$v},expression:"showPopover"}},[_c('el-autocomplete',{staticStyle:{"width":"100%"},attrs:{"clearable":"","fetch-suggestions":_vm.getLanguages,"placeholder":"Select language"},on:{"select":_vm.handleSelectLanguage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,false,1314468281),model:{value:(_vm.languageChoosen),callback:function ($$v) {_vm.languageChoosen=$$v},expression:"languageChoosen"}},[_c('i',{staticClass:"el-icon-edit el-input__icon",attrs:{"slot":"prefix"},slot:"prefix"})]),_c('pk-button',{attrs:{"slot":"reference","type":"info","round":"","disabled":_vm.availableLanguages.length ===
										_vm.languagesNumber},nativeOn:{"click":function($event){return _vm.togglePopover()}},slot:"reference"},[_c('i',{staticClass:"fa fa-plus-square"})])],1),_c('pk-button',{attrs:{"type":"danger","round":"","disabled":_vm.availableLanguages.length === 1},nativeOn:{"click":function($event){return _vm.removeTranslation()}}},[_c('i',{staticClass:"fa fa-minus-square"})])],1)]),(
						_vm.data.textResourceTranslations &&
							_vm.data.textResourceTranslations[
								_vm.selectedLanguageIndex
							]
					)?_c('div',{staticClass:"field-panel col-12"},[_c('ValidationProvider',{attrs:{"name":"Text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
return [_c('label',{staticClass:"mb-1 f"},[_vm._v(" "+_vm._s(_vm.$t("textResources.Text"))+" ")]),_c('el-input',{class:[{ 'is-invalid': errors[0] }],attrs:{"type":"textarea","rows":"8","clearable":""},model:{value:(
								_vm.data.textResourceTranslations[
									_vm.selectedLanguageIndex
								].text
							),callback:function ($$v) {_vm.$set(_vm.data.textResourceTranslations[
									_vm.selectedLanguageIndex
								], "text", $$v)},expression:"\n\t\t\t\t\t\t\t\tdata.textResourceTranslations[\n\t\t\t\t\t\t\t\t\tselectedLanguageIndex\n\t\t\t\t\t\t\t\t].text\n\t\t\t\t\t\t\t"}}),_c('span',{staticClass:"text-danger invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3566863119)})],1):_vm._e()]),_c('div',{staticClass:"rules-container panel-container"},[_c('h5',{staticClass:"rules-header"},[_vm._v(_vm._s(_vm.$t("rules.RuleSet")))]),_c('rules-list',{attrs:{"rules":_vm.ruleSetList,"resourceId":_vm.textResource.id},on:{"onRuleSave":_vm.saveRule,"onRuleDelete":_vm.deleteRule}})],1)])],1):_c('div',{staticClass:"text-center"},[_c('br'),_c('br'),_c('i',{staticClass:"now-ui-icons loader_gear spin"}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("table.Fetching"))+" ")]),_c('br'),_c('br')]),_c('div',{staticClass:"submit-button"},[_c('pk-button',{attrs:{"disabled":!_vm.loaded,"type":"info","round":""},nativeOn:{"click":function($event){return _vm.saveForm()}}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" "+_vm._s(_vm.$t("table.Save"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }