





































import { TableQuery } from "@/api/requests/TableQuery";
import { TableData } from "@/types/Table/TableData";
import { Component, Vue } from "vue-property-decorator";
import AccountForm from "@/pages/accounts/content/AccountForm.vue";
import { GetKeyCodeResponse } from "@/api/responses/keyCodes/GetKeyCodeResponse";

@Component({
	components: {
		AccountForm
	}
})
export default class ProductsTable extends Vue {
	private tableColumns = [
		{
			prop: "codeID",
			label: "#"
		},
		{
			prop: "accountName",
			label: this.$t("keyCodes.AccountName"),
			minWidth: 100
		},
		{
			prop: "productID",
			label: this.$t("keyCodes.ProductID"),
			minWidth: 100
		},
		{
			prop: "fullCode",
			label: this.$t("keyCodes.FullCode"),
			minWidth: 100
		},
		{
			prop: "available",
			label: this.$t("keyCodes.Available"),
			minWidth: 100
		},
		{
			prop: "finished",
			label: this.$t("keyCodes.Finished"),
			minWidth: 100
		},
		{
			prop: "partial",
			label: this.$t("keyCodes.Partial"),
			minWidth: 100
		},
		{
			prop: "maxUses",
			label: this.$t("keyCodes.MaxUses"),
			minWidth: 100
		},
		{
			prop: "concluded",
			label: this.$t("keyCodes.Concluded"),
			minWidth: 100
		},
		{
			prop: "isUpgrade",
			label: this.$t("keyCodes.IsUpgrade"),
			minWidth: 100
		},
		{
			prop: "isSample",
			label: this.$t("keyCodes.IsSample"),
			minWidth: 100
		}
	];
	private tableData = { results: [], count: 10, currentPage: 1 } as TableData<
		GetKeyCodeResponse
	>;
	private perPageOptions = [5, 10, 15];
	private loading = true;
	private modalShow = false;
	private modalHeader = "";

	$refs!: {
		accountFormRef: AccountForm;
	};

	async fetchData(pagination: TableQuery): Promise<void> {
		this.loading = true;
		this.tableData.results.length = 0;
		const { data, success } =
			this.$attrs.productId == null
				? await this.$keyCodes.getKeyCodes(pagination)
				: await this.$keyCodes.getKeyCodesByProductId(
						pagination,
						parseInt(this.$attrs.productId)
				  );
		if (success) {
			this.tableData = data;
		}
		this.loading = false;
	}

	handleViewAccount(id: number): void {
		this.modalHeader = this.$t("accounts.EditAccount").toString();
		this.$refs.accountFormRef.fill(id);
		this.modalShow = true;
	}

	closeModal(): void {
		this.modalShow = false;
	}

	mounted(): void {
		if (this.$route.params.id) {
			this.handleViewAccount(+this.$route.params.id);
		}
	}
}
