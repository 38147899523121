import { ReportSectionDto } from "@/api/responses/reports/dtos/ReportSectionDto";
import { Languable } from "@/interfaces/store/Languable";
import { Messageable } from "@/interfaces/store/Messageable";
import { Statable } from "@/interfaces/store/Statable";
import { Language } from "@/types/Language";
import { GetterTree } from "vuex";
import { state } from "./state";

export const getters: GetterTree<Statable, any> = {
	getEditorSections: (state): ReportSectionDto[] => state.editorSections,
	getCurrentSectionId: (state): number => state.currentSectionId,
	getMessage: (state): Messageable => state.message,
	getEditorDirty: (state): boolean => state.isEditorDirty,
	getLanguage: (): Languable =>
		!state.userLanguage ? state.defaultLanguage : state.userLanguage,
	getShortLocale: (state, getters): string =>
		getters.getLanguage.locale.substring(0, 5),
	getLanguages: (state): Languable[] => state.languages,
	getShowConfimrationModal: (state): boolean => state.showConfimrationModal,
	getStatusConfirmationModal: (state): boolean =>
		state.statusConfirmationModal,
	isAddReportForm: (state): boolean => state.isAddReportForm,
	getEditedLanguage: (state): Language | null => state.editedLanguage,
	previewErrors: (state): string[] => state.previewErrors
};
