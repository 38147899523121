import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { CdnFile } from "@/types/CdnFile";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { TableQuery } from "../requests/TableQuery";

export class Files extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getFiles(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<CdnFile>>> {
		return this.get<
			ApiResponse<TableData<CdnFile>>,
			AxiosResponse<ApiResponse<TableData<CdnFile>>>
		>("/Files", { params: tableQuery })
			.then(this.success)
			.catch(this.error);
	}

	public uploadFiles(file: FormData): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			FormData,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Files`, file)
			.then(this.success)
			.catch(this.error);
	}
}
