import english from "vee-validate/dist/locale/en.json";

const en = {
	fields: {
		Name: "Name",
		Description: "Description",
		DefaultPrice: "Default price",
		IsAGroupReport: "Is a group report?",
		NoOfKeyCodes: "# of KeyCodes",
		AddProduct: "Add product",
		ZohoProduct: "Zoho product",
		DefaultSurveyQuestionGroups: "Additional Questions",
		AssessmentOrder: "Product Assessments",
		Report: "Report",
		graphOption: "Number of Graphs to Use",
		GraphResults: "Graph to Generate Results From",
		GraphStyle: "Select Styling for Graph Comparison Report",
		AssessmentLimit: "Limit By Assessment Qty",
		ReportBalance: "Report Balance",
		Country: "Country",
		DefaultLanguage: "Default Language"
	},
	validation: english.messages
};

export default en;
