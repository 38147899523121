import { Statable } from "@/interfaces/store/Statable";

export const state: Statable = {
	message: {
		message: "",
		type: ""
	},
	editorSections: [],
	isEditorDirty: false,
	currentSectionId: 0,
	userLanguage: null,
	languages: [
		{
			locale: "en-US",
			name: "English"
		}
	],
	defaultLanguage: {
		locale: "en-US",
		name: "English"
	},
	assessmentContext: null,
	assessmentTypes: [],
	previewHtml: "",
	section: null,
	sectionId: 0,
	sectionName: "",
	showConfimrationModal: false,
	statusConfirmationModal: false,
	isAddReportForm: false,
	editedLanguage: null,
	previewErrors: []
};
