import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { TableQuery } from "../requests/TableQuery";
import { GetRuleSetResponse } from "../responses/ruleSets/GetRuleSetResponse";

export class RuleSets extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getRuleSets(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<GetRuleSetResponse>>> {
		return this.get<
			ApiResponse<GetRuleSetResponse>,
			AxiosResponse<ApiResponse<TableData<GetRuleSetResponse>>>
		>(`/RuleSets/all`, { params: tableQuery })
			.then(this.success)
			.catch(this.error);
	}
}
