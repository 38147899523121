const reports = {
	Title: "Reports",
	Name: "Name",
	Version: "Version",
	ReportPreview: "Report Preview",
	ReportInfo: "Report Info",
	AddReport: "Add Report",
	EditReport: "Edit Report",
	Description: "Description",
	NoOfProducts: "Products",
	SuccessfullyCreated: "Report successfully created",
	SuccessfullyUpdated: "Report successfully updated",
	SaveAndRunReportEditor: "Save and run report editor",
	DefaultLanguage: "Default Language",
	Languages: "Languages",
	Styles: "Styles",
	DownloadPdf: "Download PDF",
	DownloadHtml: "Download HTML",
	DownloadReport: "Download report preview",
	RemoveReport: "Remove Report",
	Id: "Id"
};

export default reports;
