









































import { Component, Ref, Vue } from "vue-property-decorator";
import FileUploadItem from "./FileUploadItem.vue";

@Component({
	components: {
		FileUploadItem
	}
})
export default class FileUploader extends Vue {
	@Ref("fileInput") fileInput!: HTMLInputElement;

	private newFiles = [] as Array<File>;
	private readonly maxFileSizeMB = 2;
	private readonly extensionReg = /(.*?)\.(jpg|bmp|jpeg|png)$/;

	getFileUrl(file: File): string {
		return URL.createObjectURL(file);
	}

	validateFile(file: File): boolean {
		const isValid =
			file.name.match(this.extensionReg) !== null &&
			file.size / 1024 / 1024 <= this.maxFileSizeMB;
		return isValid;
	}

	handleFileUploads(event: Event): void {
		const files = (event.target as HTMLInputElement).files;

		if (files) {
			for (let i = 0; i < files?.length; i++) {
				if (this.validateFile(files[i])) this.newFiles.push(files[i]);
			}
		}
		this.clearInputFiles();
	}

	clearInputFiles(): void {
		this.fileInput.value = "";
	}

	async uploadFiles() {
		const formData = new FormData();
		this.newFiles.forEach((file: File) => {
			formData.append("files", file, file.name);
		});
		await this.$files.uploadFiles(formData);
	}

	removeFile(index: number): void {
		this.newFiles.splice(index, 1);
	}
}
