import { Api } from "@/api/api";
import { TableQuery } from "@/api/requests/TableQuery";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { GetKeyCodeResponse } from "@/api/responses/keyCodes/GetKeyCodeResponse";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export class KeyCodes extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getKeyCodes(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<GetKeyCodeResponse>>> {
		return this.get<
			ApiResponse<TableData<GetKeyCodeResponse>>,
			AxiosResponse<ApiResponse<TableData<GetKeyCodeResponse>>>
		>("/KeyCodes", {
			params: tableQuery
		})
			.then(this.success)
			.catch(this.error);
	}

	public getKeyCodesByProductId(
		tableQuery: TableQuery,
		productId: number
	): Promise<ApiResponse<TableData<GetKeyCodeResponse>>> {
		return this.get<
			ApiResponse<TableData<GetKeyCodeResponse>>,
			AxiosResponse<ApiResponse<TableData<GetKeyCodeResponse>>>
		>(`/KeyCodes/ByProductId/${productId}`, {
			params: tableQuery
		})
			.then(this.success)
			.catch(this.error);
	}
}
