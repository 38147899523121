import { Api } from "@/api/api";
import { TableQuery } from "@/api/requests/TableQuery";
import { GetAccountsResponse } from "@/api/responses/accounts/GetAccountsResponse";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { CreateAccountRequest } from "../requests/accounts/CreateAccountRequest";
import { UpdateAccountRequest } from "../requests/accounts/UpdateAccountRequest";
import { GetAccountByIdResponse } from "../responses/accounts/GetAccountByIdResponse";

export class Accounts extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getAccounts(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<GetAccountsResponse>>> {
		return this.get<
			ApiResponse<TableData<GetAccountsResponse>>,
			AxiosResponse<ApiResponse<TableData<GetAccountsResponse>>>
		>("/Accounts", {
			params: tableQuery
		})
			.then(this.success)
			.catch(this.error);
	}

	public createAccount(
		data: CreateAccountRequest
	): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			CreateAccountRequest,
			AxiosResponse<ApiResponse<boolean>>
		>("/Accounts", data)
			.then(this.success)
			.catch(this.error);
	}

	public getAccountById(
		id: number
	): Promise<ApiResponse<GetAccountByIdResponse>> {
		return this.get<
			ApiResponse<GetAccountByIdResponse>,
			AxiosResponse<ApiResponse<GetAccountByIdResponse>>
		>(`/Accounts/${id}`)
			.then(this.success)
			.catch(this.error);
	}

	public updateAccount(
		id: number,
		data: UpdateAccountRequest
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			UpdateAccountRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Accounts/${id}`, data)
			.then(this.success)
			.catch(this.error);
	}

	public generateApiKey(): Promise<ApiResponse<string>> {
		return this.get<
			ApiResponse<string>,
			AxiosResponse<ApiResponse<string>>
		>("/Accounts/GenerateApiKey")
			.then(this.success)
			.catch(this.error);
	}
}
