const errors = {
	ConnectionError: "Connection Error",
	NotFound:
		"Sorry, but the page you are looking for is not found. Please, make sure you have typed the correct URL",
	FetchingSections: "Error while fetching sections",
	FetchingAssessmentTypes: "Error while fetching assessment types",
	FetchingTextResources: "Error while fetching text resources"
};

export default errors;
