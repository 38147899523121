/* eslint-disable @typescript-eslint/camelcase */
import store from "@/store";
import { User, UserManager, WebStorageStateStore } from "oidc-client";
import { CallbackSignInResponse } from "../responses/auth/CallbackSignInResponse";

export class Auth {
	private userManager: UserManager;

	constructor() {
		this.userManager = new UserManager({
			userStore: new WebStorageStateStore({
				store: window.localStorage
			}),
			authority: process.env.VUE_APP_IDENTITY_SERVER_URL,
			client_id: process.env.VUE_APP_CLIENT_ID,
			redirect_uri: `${process.env.VUE_APP_CLIENT_URL}/auth/callback`,
			silent_redirect_uri: process.env.VUE_APP_CLIENT_URL,
			response_type: "code",
			scope: "profile openid admin-scope",
			post_logout_redirect_uri: process.env.VUE_APP_CLIENT_URL,
			client_secret: process.env.VUE_APP_CLIENT_SECRET
		});
	}

	public getUser(): Promise<User | null> {
		return this.userManager.getUser();
	}

	public async isNotActive(): Promise<boolean> {
		const user = await this.userManager.getUser();
		return user?.expired ?? true;
	}

	public login(postLoginRedirectUri: string): Promise<void> {
		this.userManager.settings.extraQueryParams = {
			post_login_redirect_uri: postLoginRedirectUri
		};
		return this.userManager.signinRedirect();
	}

	public logout(): Promise<void> {
		return this.userManager.signoutRedirect();
	}

	public async callbackSignIn(): Promise<CallbackSignInResponse> {
		try {
			const result = await this.userManager.signinRedirectCallback();
			return {
				success: true,
				postLoginRedirectUri:
					result.profile.post_login_redirect_uri ?? ""
			} as CallbackSignInResponse;
		} catch (error) {
			store.dispatch("setMessage", {
				message: error,
				type: "danger"
			});
			return {
				success: false,
				postLoginRedirectUri: ""
			} as CallbackSignInResponse;
		}
	}

	public async getAccessToken(): Promise<string> {
		return this.userManager
			.getUser()
			.then((data) => data?.access_token ?? "");
	}
}
