




















import { Component, Vue, Watch } from "vue-property-decorator";
import AppNavbar from "@/components/Navbar/Navbar.vue";
import AppSidebar from "@/components/Sidebar/Sidebar.vue";
import { Action, Getter } from "vuex-class";
import { Messageable } from "./interfaces/store/Messageable";
import ACTIONS from "./store/action-definitions";
import ConfirmDeleteModal from "./components/shared/Modal/ConfirmDeleteModal.vue";

@Component({
	components: {
		AppNavbar,
		AppSidebar,
		ConfirmDeleteModal
	}
})
export default class App extends Vue {
	private pagesWithoutMenu = ["reportEditor"];
    public bannerInfo = { warningBannerContent: "", warningBannerIsEnabled: false };

	@Action(ACTIONS.SET_LANGUAGE) setLanguage: (locale: string) => void;
	@Action(ACTIONS.SET_LANGUAGES) fetchLanguages: (callback: Function) => void;
	@Getter("getMessage") message!: Messageable;
	@Watch("message")
	onMessageChanged(newVal: Messageable) {
		if (newVal.message.length > 0)
			this.$notify({
				message: newVal.message,
				icon: "now-ui-icons loader_gear",
				horizontalAlign: "right",
				verticalAlign: "bottom",
				type: newVal.type
			} as any);
	}

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	get isVisible(): boolean {
		const result = this.pagesWithoutMenu.includes(this.$route.name ?? "");
		document.body.style.overflow = result ? "hidden" : "auto"
		return result;
	}

	public toggleSidebar(): void {
		if (this.$sidebar && this.$sidebar.showSidebar === true) {
			this.$sidebar.displaySidebar(false);
		}
	}

	mounted() {
        this.GetWarningBannerInfo();
    }

	async GetWarningBannerInfo(): Promise<void> {
        const { data, success } = await this.$settings.getWarningBannerInfo(this.$store.getters.getShortLocale);
		if (success) {
			this.bannerInfo.warningBannerIsEnabled = data.isEnabled;
			this.bannerInfo.warningBannerContent = data.content;
		}
	}
}
