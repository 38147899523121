




























































import { TableQuery } from "@/api/requests/TableQuery";
import { Language } from "@/types/Language";
import { TableData } from "@/types/Table/TableData";
import { Component, Vue } from "vue-property-decorator";
import LanguageForm from "./LanguageForm.vue";
import { Action } from "vuex-class";
import ACTIONS from "@/store/action-definitions";

@Component({
	components: { LanguageForm }
})
export default class LanguagesTable extends Vue {
	private tableColumns = [
		{
			prop: "id",
			label: "#"
		},
		{
			prop: "name",
			label: this.$t("languages.Name"),
			minWidth: 200
		},
		{
			prop: "code",
			label: this.$t("languages.Code"),
			minWidth: 200
		}
	];
	private tableData = { results: [], count: 10, currentPage: 1 } as TableData<
		Language
	>;
	private loading = true;
	private modalShow = false;
	private modalHeader = "";

	@Action(ACTIONS.SET_EDITED_LANGUAGE) setEditedLanguage: (
		editedLanguage: Language | null
	) => void;

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	async fetchData(pagination?: TableQuery): Promise<void> {
		this.loading = true;
		this.tableData.results.length = 0;
		if (!pagination) {
			pagination = {
				page: 1,
				pageSize: 5,
				sortDirection: "descending",
				sortColumn: this.tableColumns[0].prop,
				searchQuery: "",
				shouldSort: false
			} as TableQuery;
		}
		const { data, success } = await this.$languages.getLanguages(
			pagination
		);
		if (success) {
			this.tableData = data;
		}
		this.loading = false;
	}

	handleAdd(): void {
		this.modalHeader = this.$t("languages.AddLanuage").toString();
		this.setEditedLanguage({
			id: 0,
			name: "",
			code: "",
			isDefault: false
		});
		this.modalShow = true;
	}

	async handleEdit(id: number): Promise<void> {
		this.modalHeader = this.$t("languages.EditLanguage").toString();
		this.setEditedLanguage(
			JSON.parse(
				JSON.stringify(
					this.tableData.results.find(
						(language) => language.id === id
					)
				)
			)
		);
		this.modalShow = true;
	}

	async handleRemove(id: number): Promise<void> {
		const { success } = await this.$languages.removeLanguage(id);
		if (success) {
			this.setMessage({
				message: this.$t("languages.SuccessfullyDeleted").toString(),
				type: "success"
			});
			this.fetchData();
		}
	}

	closeModal(): void {
		this.modalShow = false;
		this.fetchData();
	}
}
