import { apiConfig, apiPrinterConfig } from "@/api/axios-config";
import { Accounts } from "@/api/services/accounts";
import { Assessment } from "@/api/services/assessment";
import { AssessmentsTypes } from "@/api/services/assessmentsTypes";
import { Countries } from "@/api/services/countries";
import { Files } from "@/api/services/files";
import { KeyCodes } from "@/api/services/keyCodes";
import { Languages } from "@/api/services/languages";
import { Products } from "@/api/services/products";
import { Reports } from "@/api/services/reports";
import { RuleCollections } from "@/api/services/ruleCollections";
import { Rules } from "@/api/services/rules";
import { RuleSets } from "@/api/services/ruleSets";
import { SectionContents } from "@/api/services/sectionContents";
import { Sections } from "@/api/services/sections";
import { Settings } from "../api/services/settings";
import { SurveyQuestionGroups } from "@/api/services/surveyQuestionGroups";
import { TextResources } from "@/api/services/textResources";
import { ZohoProducts } from "@/api/services/zohoProducts";
import _Vue from "vue";

export default function AxiosPlugin(Vue: typeof _Vue): void {
	Vue.prototype.$reports = new Reports(apiConfig, apiPrinterConfig);
	Vue.prototype.$products = new Products(apiConfig);
	Vue.prototype.$zohoProducts = new ZohoProducts(apiConfig);
	Vue.prototype.$assessment = new Assessment(apiConfig);
	Vue.prototype.$sectionContents = new SectionContents(apiConfig);
	Vue.prototype.$sections = new Sections(apiConfig);
	Vue.prototype.$textResources = new TextResources(apiConfig);
	Vue.prototype.$surveyQuestionGroups = new SurveyQuestionGroups(apiConfig);
	Vue.prototype.$assessmentsTypes = new AssessmentsTypes(apiConfig);
	Vue.prototype.$keyCodes = new KeyCodes(apiConfig);
	Vue.prototype.$accounts = new Accounts(apiConfig);
	Vue.prototype.$settings = new Settings(apiConfig);
	Vue.prototype.$countries = new Countries(apiConfig);
	Vue.prototype.$languages = new Languages(apiConfig);
	Vue.prototype.$rules = new Rules(apiConfig);
	Vue.prototype.$ruleCollections = new RuleCollections(apiConfig);
	Vue.prototype.$files = new Files(apiConfig);
	Vue.prototype.$ruleSets = new RuleSets(apiConfig);
}

declare module "vue/types/vue" {
	interface Vue {
		$reports: Reports;
		$products: Products;
		$zohoProducts: ZohoProducts;
		$assessment: Assessment;
		$sectionContents: SectionContents;
		$sections: Sections;
		$textResources: TextResources;
		$surveyQuestionGroups: SurveyQuestionGroups;
		$assessmentsTypes: AssessmentsTypes;
		$keyCodes: KeyCodes;
		$accounts: Accounts;
		$settings: Settings;
		$countries: Countries;
		$languages: Languages;
		$rules: Rules;
		$ruleCollections: RuleCollections;
		$files: Files;
		$ruleSets: RuleSets;
	}
}
