








































































import { TableData } from "@/types/Table/TableData";
import { Component, Vue, Watch } from "vue-property-decorator";
import { TextResourceQuery } from "@/api/requests/textResource/TextResourceQuery";
import TextResourceForm from "../content/TextResourceForm.vue";
import { GetTextResourceResponse } from "@/api/responses/textResources/GetTextResourceResponse";
import { GetTextResourcesResponse } from "@/api/responses/textResources/GetTextResourcesResponse";
import { Action, Getter } from "vuex-class";
import ACTIONS from "@/store/action-definitions";

@Component({
	components: {
		TextResourceForm
	}
})
export default class TextResourcesTable extends Vue {
	private tableColumns = [
		{
			prop: "id",
			label: "Id"
		},
		{
			prop: "name",
			label: this.$t("textResources.Name"),
			minWidth: 100
		},
		{
			prop: "rulesCount",
			label: this.$t("textResources.RulesNo"),
			minWidth: 100
		},
		{
			prop: "modifiedDate",
			label: this.$t("textResources.ModifiedDate"),
			minWidth: 100
		},
		{
			prop: "isSelectableRow",
			hidden: true
		}
	];
	private tableData = { results: [], count: 10, currentPage: 1 } as TableData<
		GetTextResourcesResponse
	>;
	private loading = true;
	private currentPagination: TextResourceQuery;
	private selectedRows: Array<number> = [];
	private showModal = false;
	private selectedTextResource: GetTextResourceResponse = {
		...this.emptyTextResource
	};

	get emptyTextResource() {
		return {
			id: 0,
			name: "",
			rules: [],
			textResourceTranslations: []
		};
	}

	@Action(ACTIONS.SET_SHOW_CONFIMRATION_MODAL) setShowConfimrationModal: (
		show: boolean
	) => void;

	@Getter("getStatusConfirmationModal") statusConfirmationModal!: boolean;

	@Watch("showModal")
	onModalVisibilityChange(newVal: boolean) {
		if (!newVal) {
			this.selectedTextResource = { ...this.emptyTextResource };
		}
	}

	@Watch("statusConfirmationModal")
	async onStatusConfirmationModalChange(newVal: boolean) {
		if (newVal === true) {
			if (this.selectedRows.length) {
				const {
					success
				} = await this.$textResources.removeTextResources({
					TextResourceIds: this.selectedRows
				});
				if (success) {
					this.selectedRows = [];
					this.fetchData(this.currentPagination);
				}
			}
		}
	}

	async fetchData(pagination: TextResourceQuery): Promise<void> {
		this.currentPagination = pagination;
		this.loading = true;
		const { data, success } = await this.$textResources.getTextResources(
			pagination
		);
		if (success) {
			data.results.forEach((textResource) => {
				textResource.isSelectableRow = !textResource.isAssigned;
			});
			this.tableData = data;
		}
		this.loading = false;
	}

	async handleRemove(id: number) {
		this.selectedRows = [id];
		this.setShowConfimrationModal(true);
	}

	async handleEdit(id: number) {
		const { data, success } = await this.$textResources.getTextResource(id);
		if (success) {
			this.selectedTextResource = data;
		}
		this.showModal = true;
	}

	handleAdd() {
		this.selectedTextResource = JSON.parse(
			JSON.stringify(this.emptyTextResource)
		);
		this.showModal = true;
	}

	async handleRemoveSelected() {
		this.setShowConfimrationModal(true);
	}

	rowSelected(data: Array<GetTextResourcesResponse>) {
		this.selectedRows = data.map((resource) => resource.id);
	}

	closeModal() {
		this.fetchData(this.currentPagination);
		this.showModal = false;
	}

	mounted(): void {
		if (this.$route.params.id) {
			this.handleEdit(+this.$route.params.id);
		}
	}
}
